import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    name: "",
    userLogInEmailId: "",
  },
  reducers: {
    setName: (state: any, action: PayloadAction<object>) => {
      state.name = action.payload;
    },
    setUserLogInEmailId: (state: any, action: PayloadAction<object>) => {
      state.userLogInEmailId = action.payload;
    },
  },
});

export const { setName, setUserLogInEmailId } = authSlice.actions;

export default authSlice.reducer;
